.Aprovado{
    color: #00bf00;
}

.Negado{
    color: #ff3737;
}

.Parcialmente{
    color: #b3f996;
}

.Analise{
    color: #fff700;
}